<template>
  <div class="section-lesson">
    <div class="mb-4 mt-4">
      <h4 class="text-center">Lokasi Potensi</h4>
    </div>
    <div class="d-flex justify-content-center">
      <iframe
        width="600"
        height="450"
        style="border:0"
        loading="lazy"
        allowfullscreen
        referrerpolicy="no-referrer-when-downgrade"
        :src="embedUrl" >
      </iframe>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRef, toRefs, watch, createVNode } from 'vue'
import { useStore } from 'vuex'
import { PlusOutlined, LockOutlined, UploadOutlined, EyeOutlined, SettingOutlined, DeleteOutlined, ExclamationCircleOutlined, UnlockOutlined, EditOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { Modal, notification } from 'ant-design-vue';
import { getOne, update, getAllKanwil, getAllKC } from '@/services/axios/brivice-backend'
import apiClient from '@/services/axios'
import { useRoute, useRouter } from 'vue-router'

export default {
  components: {  },
  props: {
    course: {
      type: Object,
      default: () => { return {} },
    },
  },
  emits: ['fetchCourse'],
  setup(props, { emit }) {
    const route = useRoute()
    let dataPotensi = ref([])
    let embedUrl = ref([])
    const fetchDetail = async () => {
      const data = await getOne(route.params.id)
      dataPotensi.value = data.result
      embedUrl.value = `https://www.google.com/maps/embed/v1/place?key=AIzaSyCvevnA3eks_IsJVay4zxQDZaZRLUVsE8A&q=${dataPotensi.value.address}&center=${dataPotensi.value.lat},${dataPotensi.value.lng}`
      console.log(embedUrl.value, 'detail embedUrl')
    }
    onMounted(async () => {
      fetchDetail()
    })
    return {
      dataPotensi,
      embedUrl,
    }
  },
}
</script>

<style lang="scss">
.section-lesson {
  .ant-table td {
    word-wrap: break-word;
  }
}
</style>
